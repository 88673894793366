<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol lg="12" class="font260 text-center" style="margin-top: 20px">
        ระบบจองคิวนำเสนอผลงาน</CCol
        >
        <CCol
          lg="12"
          class="text-center"
          style="margin-bottom: 20px"
        >
          <strong>การอบรมครูหลักสูตรหุ่นยนต์และระบบอัตโนมัติ</strong><br/>
          จัดโดย สถาบันส่งเสริมการสอนวิทยาศาสตร์และเทคโนโลยี
          </CCol
        >
        <CCol lg="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardHeader class="bg-no-color">
                <i class="ri-user-5-line font260"></i
                ><strong>สำหรับครูผู้สอนระดับชั้นมัธยมศึกษาตอนต้นฯ :</strong>
              </CCardHeader>
              <CCardBody>
                <form id="formLogin" v-on:submit.prevent="booking" method="POST">
                  <SltAsset/>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="basic-addon1"
                        ><CIcon name="cil-user"
                      /></span>
                    </div>
                    <input
                      type="text"
                      name="reserver"
                      class="form-control"
                      placeholder="รหัส_ชื่อ นามสกุล_โรงเรียน"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      required
                    />
                  </div>
                  
                  <CRow>
                    <CCol col="12" class="text-right">
                      <button type="submit" class="btn btn-black btn-block" :disabled="btnDisable">
                        <span v-if="btnDisable == false">ส่ง</span>
                        <span v-else>กำลังส่ง...</span>
                        
                      </button>
                    </CCol>
                  </CRow>
                </form>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
        <CCol lg="12">
          <TblAllAsset/>
        </CCol>
      </CRow>
    </CContainer>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>

  </div>
</template>

<script>
import SltAsset from "../components/SltAsset";
import TblAllAsset from "../components/TblAllAsset";
export default {
  name: "Login",
  components:{
    SltAsset,
    TblAllAsset
  },
  data() {
    return {
      darkModal: false,
      btnDisable:false,
    };
  },
  methods: {
    booking(event) {
      //console.log(this.$store.state.selectedAsset.length);
      if((this.$store.state.selectedAsset).length == 0){
        alert("กรุณาระบุช่วงเวลา");
        return false;
      }
      this.btnDisable = true;
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("assetId", this.$store.state.selectedAsset.code);

      this.axios
        .post(this.$hostUrl + "php_action/reservationAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if(response.data.createState == 'created'){
            alert("บันทึกสำเร็จ");
            location.reload();
          }
          else{
            alert(response.data.error);
          }
        });
    },

    roughSizeOfObject(object) {
      var objectList = [];
      var stack = [object];
      var bytes = 0;

      while (stack.length) {
        var value = stack.pop();

        if (typeof value === "boolean") {
          bytes += 4;
        } else if (typeof value === "string") {
          bytes += value.length * 2;
        } else if (typeof value === "number") {
          bytes += 8;
        } else if (
          typeof value === "object" &&
          objectList.indexOf(value) === -1
        ) {
          objectList.push(value);

          for (var i in value) {
            stack.push(value[i]);
          }
        }
      }
      return bytes;
    },
  },
};
</script>
